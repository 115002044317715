import React from 'react';
import "./accordion.css";

const AccordionComponent = ({ accordionItems, title, info }) => {
    return (
        <div className="container p-4 bg-light mt-5 mb-5">
            <h3 className="mb-3">
                {title}
            </h3>
            <p className='text-left'>{info}</p>
            <div className="accordion accordion-flush" id="accordionFlushExample">
                {accordionItems.map((item, index) => (
                    <div key={index} className="accordion-item rounded-3 border-0 shadow mb-2">
                        <h2 className="accordion-header">
                            <button
                                className="accordion-button border-bottom collapsed fw-semibold"
                                type="button"
                                data-bs-toggle="collapse"
                                data-bs-target={`#${item.id}`}
                                aria-expanded="false"
                                aria-controls={item.id}
                            >
                                {item.title}
                            </button>
                        </h2>
                        <div
                            id={item.id}
                            className="accordion-collapse collapse"
                            data-bs-parent="#accordionFlushExample"
                        >
                            <div className="accordion-body">
                                <p>
                                    {item.content}
                                    <a href={item.link}>Codepen</a>
                                </p>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
}

export default AccordionComponent;
