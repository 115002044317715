import React from 'react';
import "./LogoSection.css";

const LogoSection = ({ main, secondary, backgroundImage }) => {
  return (
    <div
      id="main"
      className="home-wrapper"
      style={{
        backgroundImage: `url(${backgroundImage})`,
      }}
    >
      <div className="background-overlay"></div>
      <div className="container">
        <div className="row mt-2">
          <div className="col-md-12 text-white text-center">
            <img src={'/images/CAIT-Logo-White-Highlight-300x92.png'} alt="logo" className="logo" />
            <h1 className="white-text">{main}</h1>
            <p className="lead white-text">{secondary}</p>
            <a className="main-button icon-button" href="#">
              Get Started!
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LogoSection;
