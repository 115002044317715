import React from 'react';
import Header from "../components/Header/Header";
import BlogGreed from '../components/SingleBlogPost/BlogGreed/BlogGreed';
import LogoSection from '../components/LogoSection/LogoSection';
import Footer from '../components/Footer/Footer';

const BlogPage = () => {
  return (
    <>
      <Header />
      <LogoSection
        main="Blog Posts"
        secondary="" 
        backgroundImage="/images/home-background.jpeg" // Updated image path
      />
      <BlogGreed />
    <Footer/>
    </>
  );
}

export default BlogPage;
