import React from 'react';
import './IndustriesSection.css';

const IndustriesSection = () => {
  const services = [
    { icon: 'fa-solid fa-graduation-cap', title: 'Apprentice Development', description: 'CAIT is the foremost government recognized TPA (Third Party Aggregator) under National Apprenticeship Promotion Scheme (NAPS) and ranked No 1 in India by AON.' },
    { icon: 'fa-solid fa-personfa-solid fa-people-group', title: 'Manpower Services', description: 'Whether you are currently recruiting, looking for advice on HR compliance or considering contract staffing, you may count on our vast experience to deliver your goals.' },
    { icon: 'fa-solid fa-landmark', title: 'Business Outsourcing', description: 'Our business outsourcing services can improve your business experience & streamline internal processes.' },
    { icon: 'fa-solid fa-user-graduate', title: 'Learning Services', description: 'Lorem ipsum dolor, sit amet consectetur adipisicing elit. Quisquam consequatur necessitatibus eaque.' },
  ];

  return (
    <section id="advertisers" className="advertisers-service-sec pt-5 pb-5">
      <div className="container">
        <div className="row">
          <div className="section-header text-center">
            <h2 className="fw-bold fs-1">
              Our
              <span className="b-class-secondary"> Services </span>
            </h2>-
            <p className="sec-icon"><i className="fa-solid fa-gear"></i></p>
          </div>
        </div>
        <div className="row mt-5 mt-md-4 row-cols-1 row-cols-sm-2 row-cols-lg-4 justify-content-center">
          {services.map((service, index) => (
            <div className="col" key={index}>
              <div className="service-card">
                <div className="icon-wrapper">
                  <i className={`fa-solid ${service.icon}`}></i>
                </div>
                <h3>{service.title}</h3>
                <p>{service.description}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default IndustriesSection;
