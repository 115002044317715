import React from 'react'
import "./Footer.css"

const Footer = () => {
  return (
    <>
    <footer id="footer" className="bg-dark text-white py-5">
      <div className="container">
        <div className="row mb-4">
          <div className="col-12 text-center mb-3">
            <div className="footer-logo">
              <a className="logo" href="index.html">
                <img src="/images/CAIT-Logo-White-Highlight-300x92.png" alt="logo" className="img-fluid logo"/>
              </a>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-12 col-md-6 text-center text-md-start mb-3 mb-md-0">
            <p className="text-white">
              CAIT Edusys Pvt. Ltd. Plot No.9, Racheta CHS, Sector- 21, Yamuna Nagar, Pune, Maharashtra - 411044
            </p>
            <ul className='footer-social d-flex justify-content-center justify-content-md-start gap-2 list-unstyled'>
              <li><a href="#" className="facebook text-white"><i className="fa-brands fa-facebook-f"></i></a></li>
              <li><a href="#" className="twitter text-white"><i className="fa-brands fa-twitter"></i></a></li>
              <li><a href="#" className="google-plus text-white"><i className="fa-brands fa-google-plus-g"></i></a></li>
              <li><a href="#" className="instagram text-white"><i className="fa-brands fa-instagram"></i></a></li>
              <li><a href="#" className="youtube text-white"><i className="fa-brands fa-youtube"></i></a></li>
              <li><a href="#" className="linkedin text-white"><i className="fa-brands fa-linkedin-in"></i></a></li>
            </ul>
          </div>

          <div className="col-12 col-md-6 text-center text-md-start">
            <div className="row">
              <div className="col-6 mb-3">
                <p className="text-white"><a href='' className=''>Apprenticeship</a></p>
                <p className="text-white"><a href='' className=''>Business Services</a></p>
                <p className="text-white"><a href='' className=''>Jobs</a></p>
              </div>
              <div className="col-6 mb-3">
                <p className="text-white"><a href='' className=''>The Big Picture</a></p>
                <p className="text-white"><a href='' className=''>Privacy Policy</a></p>
                <p className="text-white"><a href='' className=''>General Privacy Policy</a></p>
              </div>

            </div>
          </div>
        </div>
      </div>
    </footer>
    </>
    
  )
}

export default Footer