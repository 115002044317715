import React from "react";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import LogoSection from "../../components/LogoSection/LogoSection";
import ContactCTASection from "../../components/ContactCTA/ContactCTASection Component";
import ServiceComponent from "../../components/ServiceComponent/ServiceComponent";

const Apprenticeship = () => {
  return (
    <>
      <Header />
      <LogoSection
        main="Welcome to CAIT Edusys Pvt Ltd."
        secondary="Leading Skill Development and Human Resource Solutions."
        backgroundImage="/images/home-background.jpeg" // Path relative to public
      />
      <div className="container">
        <div className="row">
          <div className="section-header text-center mt-5 mb-2">
            <h2 className="fw-bold fs-1">
              Advantages of
              <span className="b-class-secondary"> Apprenticeship</span>
            </h2>
            <p className="sec-icon">
              <i className="fa-solid fa-gear"></i>
            </p>
          </div>
        </div>
        <div className="row">
          <div className="section-header ">
            <ServiceComponent
              imageSrc="/images/apprentice.jpeg" // Path relative to public
              heading="For the apprentice"
              info=" It provides a terrific opportunity to achieve practical training on the job which compliments skill-based education. This is the ideal start to a great career with government certification of the skilled candidate. Apprenticeship transforms a trainee into a trained employee."
            />
            <ServiceComponent
              imageSrc="/images/for-oragnization.jpeg" // Path relative to public
              heading="For the organization"
              info=" Apprenticeship reduces cost by leveraging CSR funds, it ensures a long term source of skilled manpower even for perineal job roles and eases IR issues. What’s more, through mandatory training programs, each resource can be moulded to suit exact requirements of the organization."
            />
            <ServiceComponent
              imageSrc="/images/for-goverment.jpg"// Path relative to public
              heading="For government" 
              info="Apprenticeship program ensures skilling of workforce as per industry requirements and aligns with other development initiatives. As a country with over 50% of population below 25 years of age, India’s aspiration to become the skill capital of the world is being rapidly unlocked with the growing momentum on skilling and upskilling. To know more about apprenticeship, its advantages and details of privileges, schedule a call with us today."
            />
             <ServiceComponent
              imageSrc="/images/WILP.jpeg" // Path relative to public
              heading="Work integrated Learning (WILP)"
              info="Apprenticeship program ensures skilling of workforce as per industry requirements and aligns with other development initiatives. As a country with over 50% of population below 25 years of age, India’s aspiration to become the skill capital of the world is being rapidly unlocked with the growing momentum on skilling and upskilling. To know more about apprenticeship, its advantages and details of privileges, schedule a call with us today."
            />
          </div>
        </div>
      </div>

      <ContactCTASection
        main="In Today's world of contracts, CAIT builds partnerships"
        tagline=""
        info="Essentially in every partnership lies our effort to comprehend your business and build meaningful solutions that add value and drive agility into your business. Our team comprises some of the brightest sparks from the industry with each individual committed to your success."
      />

      <Footer />
    </>
  );
};

export default Apprenticeship;
