import React from 'react';
import { Link } from 'react-router-dom';
import Header from '../components/Header/Header';
import Footer from '../components/Footer/Footer';
import './NotFound.css'; 

const NotFound = () => {
  return (
    <>
      <Header />
      <div className="not-found-container text-center">
        <h1 className="display-1">404</h1>
        <p className="lead">Sorry, the page you are looking for does not exist.</p>
        <Link to="/" className="btn btn-primary mt-3">Go Back Home</Link>
      </div>
      <Footer />
    </>
  );
};

export default NotFound;
