import React from 'react'
import LogoSection from '../components/LogoSection/LogoSection';
import Header from '../components/Header/Header';
import Footer from '../components/Footer/Footer';
import LogoSlider from '../components/LogoSlider/LogoSlider';
import IndustriesSection from '../components/IndustriesSection/IndustriesSection';
import FounderInfo from '../components/FounderInfo/FounderInfo';
import ContactCTASection from '../components/ContactCTA/ContactCTASection Component';


const BigPicture = () => {
  return (
    <>
    <Header/>
    <LogoSection
    main="20 years,1100 industry relations"
    secondary=""
    backgroundImage="/images/home-background.jpeg"
    />
    <LogoSlider/>
    <IndustriesSection/>
    <FounderInfo/>

    <ContactCTASection
    main="20 Years of Excellence" 
    tagline="" 
    info="Ever since its inception in 2001, CAIT has been involved in the area of skills upgradation and training. In 2016 it joined the Skill India Mission in alignment with the National Skills Development Council. Today, CAIT ranks among the country’s highest quality TPA for NAPS as well as a respected player in its other verticals. As India grew from strength to strength, CAIT too kept pace- ensuring that rural and impoverished youths find a way to hone their talent and thereby find their place in the mainstream of business and industry. It is this quest that added wings to CAIT’s dreams- an enduring desire to nurture, hone and foster talent… a desire to truly touch lives… the dream to ensure that the potential that exists in the 1.3billion lives across the country can be unlocked. As the relationships with industry and clients deepened, CAIT kept pace with the market requirements. From its strong hold of Apprentice Solutions it expanded its portfolio into areas of Manpower services, Business Outsourcing Services as well as Learning Services. Today, CAIT is a professionally managed, integrated service provider enjoying the confidence and trust of some of best loved brands in the world as well as a vast array of SME’s across sectors and is headquartered in Pune, Maharashtra."/>
    <Footer/>
    </>
  )
}

export default BigPicture;