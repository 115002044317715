import React from 'react'

import LogoSection from '../../components/LogoSection/LogoSection';
import Header from '../../components/Header/Header';
import ContactCTASection from '../../components/ContactCTA/ContactCTASection Component';
import Footer from '../../components/Footer/Footer';
import AccordionComponent from '../../components/AccordionComponent/AccordionComponent';

const BusinessOutsourcing = () => {

      const accordionItemsA = [
        {
            id: 1,
            title: 'Financial and Accounting Services',
            content: `CAIT’s finance and accounting outsourcing services are outlined to support the client by setting up compliance and controls, equalizing expenditure and growth, reducing cash cycle and increasing your ROI. Our accounting services include the following offerings:  
                          Book keeping services,
                          Accounts payable,
                          Accounts receivable,
                          Bank reconciliation,
                          Financial reporting,
                          Payroll processing,
                          Tax preparation `,
            
        },

      {
          id: 'flush-collapseOne2',
          title: 'HR processes outsourcing services',
          content: `People are the centrepiece of every organization and every leader understands that the management of Human Resources is often time consuming. What’s more, as your business grows, you need to ensure robust HR practices without spending an arm and a leg for specialists with necessary expertise in the area. This is where CAIT can help.
          With your HR needs outsourced to us, you can be assured of strategic and tactical services you can rely on. As our client, you’ll be assigned a dedicated, experienced professional to handle all of your HR needs, while our team can manage your requirements.
          Talent Acquisition
            Onboarding
            Development of Policies, Procedures, & Processes
            Development of Career Paths
            Defining Training & Development Needs
            Benefits Management
          If full-scale HR outsourcing isn’t the right fit for your organization, you can still engage with us. We also offer most of the same services on a project-by-project basis.
          `,
      },
        {
          id: 'flush-collapseOne2',
          title: 'Bulk Hiring',
          content: `In specific industries related to infrastructure, logistics, and industrial development, there is often the requirement for workforce in significant volumes. At such times our clients rely upon us to deliver their mandates- especially for blue collar requirements. During the bulk hiring process, we also extend our service in collecting the required credentials as per employer requirement. Our core strength lies in hiring the rural youths who are ready to migrate as per job location.`,
          
      },
      
      ];

    return (
        <>
        <Header/>

          <LogoSection
            main="Business Outsourcing"
            secondary="Experts that deliver Value
                       Financial and Accounting Services, HR processes outsourcing"
            backgroundImage="/images/home-background.jpeg"
          />

          <AccordionComponent
              accordionItems={accordionItemsA}
              title="Services"
              info="Administrative responsibilities can tax any company, but for small and medium scale businesses in particular, they can be a heavy burden given current volatility in ecosystem. When you run a company with limited time and resources, the last thing you want to do is divert your attention from the core business that’s your bread and butter. Today, CFOs and CPAs across the globe seek out accounting outsourcing firms that can assist them in reducing the company’s operating costs, increasing profits and establishing benchmarks for superior governance and conformity to regulations.
              This is where CAIT comes in: our business outsourcing services are designed to improve your business experience & streamline internal processes. We commit to your success- reducing cost, increasing service levels and improving the enterprise value of your business processes."
          />
         

          <ContactCTASection
            main="In Today's world of contracts, CAIT builds partnerships"
            tagline=""
            info="Essentially in every partnership lies our effort to comprehend your business and build meaningful solutions that add value and drive agility into your business. Our team comprises some of the brightest sparks from the industry with each individual committed to your success."
          />

        <Footer/>

        </>
      );
  
}

export default BusinessOutsourcing;