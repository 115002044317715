// BlogPost.js
import React from 'react';
import { Link } from 'react-router-dom';

const Post = ({ post }) => {
  return (
    <div className="col-lg-4 col-md-6">
      <div className="card mb-4">
        <a><img className="card-img-top" src={post.image} alt="Blog post" /></a>
        <div className="card-body">
          <div className="small text-muted">{post.date}</div>
          <h2 className="card-title h4">{post.title}</h2>
          <p className="card-text">{post.content.substring(0, 100)}...</p>
          <Link to={`/post/${post.id}`} className="btn btn-primary">Read more →</Link>
        </div>
      </div>
    </div>
  );
};

export default Post;