import React from "react";
import "./LogoService.css";

const LogoService = () => {
  return (
    <div className="container mt-5">
      <div className="row">
        <div className="col-md-3 col-lg-3">
          <img
            src="/images/Skill-India-Logo.png"
            alt="Skill-India-Logo"
            className="logo-left img-fluid"
          />
        </div>

        <div className="col-md-3 col-lg-3 ms-auto">
          <img
            src="/images/Government_of_India.png"
            alt="Government_of_India"
            className="logo-right img-fluid"
          />
        </div>
      </div>

      <div className="row mt-5 d-flex">

      <div className="col-6  text-center">
          <img src="/images/Aon.png" alt="Aon-logo" className="img-fluid Aon_logo mb-2" />
          <h6>Highest Ranked Third Party Aggregator Certified by</h6>
      </div>

        {/* <div className="col-2 mx-auto"></div> */}
        <div className="col-6  text-center">
          <img src="/images/NAPS-Logo-small-121x150.png" alt="NAPS-Logo" className="img-fluid NAPS-Logo mb-2" />
          <h6>Highest Ranked Third Party Aggregator Certified by</h6>
        </div>

      </div>
    </div>
  );
};

export default LogoService;
