import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

import Home from './pages/Home';
import BigPicture from './pages/BigPicture';
import BlogPage from './pages/BlogPage';
import ContactPage from './pages/ContactPage';

import ServicesPage from './pages/ServicesPage';
import Apprenticeship from './pages/ServicesPages/Apprenticeship';
import BusinessOutsourcing from './pages/ServicesPages/BusinessOutsourcing';
import SkillDevelopment from './pages/ServicesPages/SkillDevelopment';
import ManpowerServices from './pages/ServicesPages/ManpowerServices';
import LearningSolutions from './pages/ServicesPages/LearningSolutions';

import NotFound from './pages/NotFound'; 

import Lms from './pages/Lms';

import "./App.css"




const App = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/big-picture" element={<BigPicture />} />
        <Route path="/blog" element={<BlogPage />} />
        <Route path="/contact" element={<ContactPage />} />
        <Route path="/services" element={<ServicesPage />} />
        <Route path="/lms" element={<Lms />} />


        {/* Nested or additional routes */}
        <Route path="/services/apprenticeship" element={<Apprenticeship />} />
        <Route path="/services/business-outsourcing" element={<BusinessOutsourcing />} />
        <Route path="/services/skill-development" element={<SkillDevelopment />} />
        <Route path="/services/manpower-services" element={<ManpowerServices />} />
        <Route path="/services/learning-solutions" element={<LearningSolutions />} />

        {/* Fallback route for 404 page */}
        <Route path="*" element={<NotFound />} />
      </Routes>
    </Router>
  );
};

export default App;
