import React, { useEffect, useState } from 'react';
import Post from "../Post";
import Footer from '../../Footer/Footer';

const BlogGreed = () => {
  const [posts, setPosts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  // useEffect(() => {
  //   const fetchPosts = async () => {
  //     try {
  //       const response = await fetch('/api/posts'); // Adjust the endpoint as needed
  //       if (!response.ok) {
  //         throw new Error('Network response was not ok');
  //       }
  //       const data = await response.json();
  //       setPosts(data);
  //     } catch (error) {
  //       console.error('Error fetching blog posts:', error);
  //       setError('Failed to load blog posts.');
  //     } finally {
  //       setLoading(false);
  //     }
  //   };

  //   fetchPosts();
  // }, []);

  return (
    <>
      <div id="BlogGreed" className='container p-5'>
        <div className='row'>
          {loading ? (
            <div className="col-12">
              <p>Loading...</p>
            </div>
          ) : error ? (
            <div className="col-12">
              <p>{error}</p>
            </div>
          ) : posts.length > 0 ? (
            posts.map(post => (
              <div key={post.id} className="col-lg-4 col-md-6 mb-4">
                <Post
                  imgSrc={post.image || `https://dummyimage.com/700x350/dee2e6/6c757d.jpg`}
                  date={post.date}
                  title={post.title}
                  description={post.content}
                  link={`/blog/${post.id}`}
                />
              </div>
            ))
          ) : (
            <div className="col-12">
              <p>No posts available.</p>
            </div>
          )}
        </div>
      </div>
     
    </>
  );
};

export default BlogGreed;
