import React from 'react';
import "./CarouselSection.css";

const CarouselSection = () => {
  const slides = [
    {
      image: '',
      heading: "Our preferred advisors in IR matters",
      caption: "Parag Shah MD- Unique Circle",
    },
    {
      image: 'path-to-image2.jpg',
      heading: "Genuine people transformation through training: we have explored itwith CAIT",
      caption: "VP- HR of India's premier specialty chemicals company",
    },
    {
      image: 'path-to-image3.jpg',
      heading: `We have achieved 8% reduction in overall manpower cost, thanks to CAIT`,
      caption: `Manjit Dey DIFM360`,
    },
    
    {
      image: 'path-to-image4.jpg',
      heading: "CAIT's team brings deep commitment into their relationship- this introduces new life and energy to businesses.",
      caption: "Sudhir Misra Integrated Logistics",
    },
    {
      image: 'path-to-image5.jpg',
      heading: "10 years collaboration with CAIT; looking forward to the next decade",
      caption: "Director HR of India's leading 2-wheeler manufacturer",
    },
    {
      image: 'path-to-image5.jpg',
      heading: "Total compliance, IR efficiency, reduced cost & higher productivity 10 years collaboration with CAIT; looking forward to the next decade", 
      caption: "VP-HR of India's largest corporate nutrition management group",
    },

    {
      image: 'path-to-image5.jpg',
      heading: "We have achieved 8% reduction in overall manpower cost, thanks to CAIT", 
      caption: "VP- HR of India's premier specialty chemicals company",
    },
  ];

  return (
    <div className="container my-2 ">
      <h1 className="text-center heading">CAIT Delivers</h1>
      <hr className="hr-color" />
      <p className="text-center">Total compliance, IR efficiency, reduced cost & higher productivity</p>
      <div id="carouselExampleDark" className="carousel carousel-dark slide" data-bs-ride="carousel">
        <div className="carousel-indicators">
          {slides.map((_, index) => (
            <button
              key={index}
              type="button"
              data-bs-target="#carouselExampleDark"
              data-bs-slide-to={index}
              className={index === 0 ? 'active' : ''}
              aria-current={index === 0 ? 'true' : 'false'}
              aria-label={`Slide ${index + 1}`}
            ></button>
          ))}
        </div>
        <div className="carousel-inner">
          {slides.map((slide, index) => (
            <div
              key={index}
              className={`carousel-item ${index === 0 ? 'active' : ''}`}
              data-bs-interval="5000"
            >
              <div
                className="d-block w-100"
                style={{
                  backgroundImage: `url('${slide.image}')`,
                  minHeight: '200px',
                  backgroundSize: 'cover',
                  backgroundPosition: 'center',
                }}
              >
                <div className="carousel-caption d-block mb-3">
                  <h5>{slide.heading}</h5>
                  <p>{slide.caption}</p>
                </div>
              </div>
            </div>
          ))}
        </div>
        <button
          className="carousel-control-prev"
          type="button"
          data-bs-target="#carouselExampleDark"
          data-bs-slide="prev"
        >
          <span className="carousel-control-prev-icon" aria-hidden="true"></span>
          <span className="visually-hidden">Previous</span>
        </button>
        <button
          className="carousel-control-next"
          type="button"
          data-bs-target="#carouselExampleDark"
          data-bs-slide="next"
        >
          <span className="carousel-control-next-icon" aria-hidden="true"></span>
          <span className="visually-hidden">Next</span>
        </button>
      </div>
    </div>
  );
};

export default CarouselSection;
