import React from 'react';
import LogoSection from '../../components/LogoSection/LogoSection';
import Header from '../../components/Header/Header';
import ServiceComponent from '../../components/ServiceComponent/ServiceComponent';
import Footer from '../../components/Footer/Footer';
import AccordionComponent from '../../components/AccordionComponent/AccordionComponent';

const SkillDevelopment = () => {
  const accordionItemsA = [
    {
      id: 1,
      title: 'Read More',
      content: `Our programs are curated to the needs of the industry and also offer holistic development- ensuring trade-related skills and hands-on training. We emphasize on personal development through training in areas like functional English, digital literacy and life skills.

Our curricula are designed by experts to industry-endorsed National Occupational Standards (NOS). They range across various levels of the National Skills Qualifications Framework (NSQF) and cater to a wide range of learner segments ranging from students in school (Class IX-XII), school dropouts and diploma holders, to undergraduates and graduates, working professionals, and artisans.`,

    },
    
  ];
  return (
    <>
      <Header />
      <LogoSection
        main="Skill Development"
        secondary="Funded Partner of National Skills Development Corporation"
        backgroundImage="/images/home-background.jpeg"
      />
      <AccordionComponent
        accordionItems={accordionItemsA}
        title="Manpower Services"
        info="Are you currently recruiting, looking for advice on HR compliance or considering contract staffing? In each of these areas, you may count on our vast experience to deliver your goals. Every year, we place 4000+ candidates across various job roles and sectors. This is just the beginning- because companies all across are exploring options to keep the asset light, reduce overhead costs, and stay focused on their core business operations and growth."
      />
      <Footer />
    </>
  );
};

export default SkillDevelopment;
