import React from 'react';
import ContactForm from '../components/ContactForm/ContactForm';
import Header from '../components/Header/Header';
import Footer from '../components/Footer/Footer';
import LogoSection from '../components/LogoSection/LogoSection';

const ContactPage = () => {
  return (
    <>
      <Header/>
      <LogoSection
        main="Contact Us" 
        secondary="" 
        backgroundImage="/images/home-background.jpg"
      />
      <ContactForm/>
      <Footer/>
    </>
  );
};

export default ContactPage;