import React from 'react';
import LogoSection from '../../components/LogoSection/LogoSection';
import Header from '../../components/Header/Header';
import ServiceComponent from '../../components/ServiceComponent/ServiceComponent';
import ContactCTASection from '../../components/ContactCTA/ContactCTASection Component';
import Footer from '../../components/Footer/Footer';
import AccordionComponent from '../../components/AccordionComponent/AccordionComponent';

const LearningSolutions = () => {
  const accordionItemsA = [
    {
      id: 1,
      title: 'Facility Management Trainings',
      content: `Virtual Live Courses. Bring the classroom to the computer as your team will learn from our instructors who are reputed industry veterans with global exposure. Your team will learn best practices to run your facilities more effectively and efficiently. They will gain confidence in performing their role with in-demand skills in addition to imbibing critical thinking skills essential to managing complex facilities. The course and modules are customized depending on needs of your business and team.`,

    },

    {
      id: 2,
      title: 'Soft skills & Motivational training',
      content: `The CAIT Team is here with the tools that your organization needs… to enhance productivity, drive employee engagement, and facilitate a positive work environment.`,
    },
    {
      id: 3,
      title: 'Coaching for Engagement',
      content: `Designed to teach supervisors how effective coaching and feedback can address performance and impact employee engagement. Participants will imbibe the coaching mind set and learn to effectively deliver feedback.`,
    },
    {
      id: 4,
      title: 'Performance mindset development',
      content: `A development focused program that realigns attitudes, engagement, teamwork, and resiliency. Combined with meditation and mindfulness sessions the program focuses on mental training to enable personal transformation and deliver transformational work culture.`,
    },
    {
      id: 5,
      title: 'Workplace etiquettes 101',
      content: `Designed for people newly joining or returning to the workforce, this program covers workplace expectations and acceptable behaviours, appropriateness in conduct, communication, time management and general professional expectations. The program is customized to address the needs and challenges of specific organizations.`,
    },

  ];
  return (
    <>
      <Header />
      <LogoSection
        main="Learning Solutions"
        secondary="Instructor led or eLearning… our specialists deliver
                  Facility Management, Soft Skills, Coaching, Performance Mindset, Etiquette"
        backgroundImage="/images/home-background.jpeg" // Path relative to public
      />
      <AccordionComponent
        accordionItems={accordionItemsA}
        title="Services"
        info="Nurturing employees into future company leaders is every organization’s primary goal. This is where CAIT’s experience of delivering face-to-face and custom eLearning modules come in handy. Our structured engagements deliver impact and transformation targeting behavioural as well as hard-skills development"
      />

      <ContactCTASection
        main="In Today's world of contracts, CAIT builds partnerships"
        tagline=""
        info="Essentially in every partnership lies our effort to comprehend your business and build meaningful solutions that add value and drive agility into your business. Our team comprises some of the brightest sparks from the industry with each individual committed to your success."
      />

      <Footer />
    </>
  );
};

export default LearningSolutions;
