import React from "react";
import { Link } from "react-router-dom";
import "./Header.css";


const Header = () => {
  return (
    <header id="header" className="transparent-nav bg-dark text-white py-3">
      <div className="container">
        <nav className="navbar navbar-expand-lg navbar-light">
          {/* Logo */}
          <Link to="/" className="navbar-brand d-flex align-items-center">
            <img src={`/images/CAIT-Logo-White-Highlight-300x92.png`} alt="logo" className="img-fluid" />
          </Link>
          {/* Mobile toggle */}
          <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
            <span className="navbar-toggler-icon"></span>
          </button>
          {/* Navigation */}
          <div className="collapse navbar-collapse" id="navbarNav">
            <ul className="navbar-nav ms-auto">
              <li className="nav-item">
                <Link to="/" className="nav-link">Home</Link>
              </li>
              <li className="nav-item dropdown">
                <Link to="/services" className="nav-link dropdown-toggle" id="servicesDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                  Services
                </Link>
                <ul className="dropdown-menu" aria-labelledby="servicesDropdown">
                  <li><Link to="/services/apprenticeship" className="dropdown-item">Apprenticeship</Link></li>
                  <li><Link to="/services/business-outsourcing" className="dropdown-item">Business Outsourcing</Link></li>
                  <li><Link to="/services/skill-development" className="dropdown-item">Skill Development</Link></li>
                  <li><Link to="/services/manpower-services" className="dropdown-item">Manpower Services</Link></li>
                  <li><Link to="/services/learning-solutions" className="dropdown-item">Learning Solutions</Link></li>
                </ul>
              </li>
              <li className="nav-item">
                <Link to="/big-picture" className="nav-link">Big Picture</Link>
              </li>
              <li className="nav-item">
                <Link to="/blog" className="nav-link">Blog</Link>
              </li>
              <li className="nav-item">
                <Link to="/contact" className="nav-link">Contact</Link>
              </li>
              <li className="nav-item">
                <Link to="/lms" className="nav-link">LMS</Link>
              </li>
            </ul>
          </div>
        </nav>
      </div>
    </header>
  );
};

export default Header;