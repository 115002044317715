import React from 'react';
import "./ContactCTA.css";

const ContactCTASection = ({ main, tagline, info }) => {
  return (
    <div id="cta" className="section mb">
      <div className="background-overlay"></div>
      <div className="container p-2">
        <div className="row mt-2">
          <div className="col-md-12 text-white">
            <img 
              src="/images/CAIT-Logo-White-Highlight-300x92.png" 
              alt="logo" 
              className="logo" 
            />
            <h1 className="white-text">{main}</h1>
            <p className="lead white-text">{tagline}</p>
            <p className="lead white-text">{info}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactCTASection;
