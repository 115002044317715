import React from 'react';
import './LogoSlider.css'; 

const LogoSlider = () => {
  const logos = [
    '/images/1-Diageo.png',
    '/images/8-Compass-Group.png',
    '/images/2-BharatBenz-150x133.png',
    '/images/Century-Enka-Logo.png',
    '/images/1-Diageo.png',
    '/images/5-United-Spirits.png',
    '/images/Baramati-Agro-logo.png',
    '/images/Principal-Logo.png',
    '/images/Kalyani-Forge.png',
    '/images/Mahindra-CIE-Logo.png',
    '/images/3-Piaggio-150x60.png',
    '/images/10-Gilera.png',
    '/images/4-Aprilia-150x75.png',
    '/images/7-TATA-Autocomp-150x70.png',
    '/images/Wirtgen-Group-Logo.png'
];

  return (
    <div className="slider">
      <div className="slide-track">
        {logos.map((logo, index) => (
          <div className="slide" key={index}>
            <img className="logo_img" src={logo} alt={`Logo ${index + 1}`} />
          </div>
        ))}
      </div>
    </div>
  );
};

export default LogoSlider;
