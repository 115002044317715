import React from 'react'
import Header from '../components/Header/Header'
import LogoSection from '../components/LogoSection/LogoSection'
import ServiceComponent from '../components/ServiceComponent/ServiceComponent'
import Footer from '../components/Footer/Footer'
import LogoService from '../components/LogoService/LogoService'

const ServicesPage = () => {
  return (
    <>
   <Header/>
   
   <LogoSection
   main="Our Services"
   backgroundImage="/home-background.jpeg"
   />
   <LogoService/>
   <ServiceComponent
   imageSrc="/photo_1.jpg" heading="Skill Development" info="
Skill Development
As a funded partner of National Skills Development Corporation (NSDC), CAIT is committed to ensure inclusive growth for India. We are one of India’s foremost Skills Development Organizations, committed to transform rural as well as socially disadvantaged communities through placement-linked skill development, livelihood creation, entrepreneurship and self-employment."
   />
   <ServiceComponent
   imageSrc="/photo_1.jpg" heading="service 1" info="adjansdjsfjdbgjdbjdfbhdfhdbvhfbvdbvf"
   />
   <ServiceComponent
   imageSrc="/photo_1.jpg" heading="service 1" info="adjansdjsfjdbgjdbjdfbhdfhdbvhfbvdbvf"
   />
   <ServiceComponent
   imageSrc="/photo_1.jpg" heading="service 1" info="adjansdjsfjdbgjdbjdfbhdfhdbvhfbvdbvf"
   />

   <Footer/>




   
    
    
    
    
    
    </>
  )
}

export default ServicesPage