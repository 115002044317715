import React from 'react'
import Header from '../components/Header/Header'
import Footer from '../components/Footer/Footer'
import LogoSection from '../components/LogoSection/LogoSection'
import CarouselSection from '../components/Carasole/CarouselSection'
import CTASection from '../components/CTAsection/CTASection Component'
import CardsGrid from '../components/ServiceCard/Cardgreed/CardsGrid'
import ContactCTASection from '../components/ContactCTA/ContactCTASection Component'
import IndustriesSection from '../components/IndustriesSection/IndustriesSection'




const Home = () => {
  return (
    <>
      <Header />
      <LogoSection
        main="Welcome to CAIT Edusys Pvt Ltd."
        secondary="Leading Skill Development and Human Resource Solutions."
        backgroundImage="/images/home-background.jpeg"
      />
      <IndustriesSection/>
      
      <CTASection
        main="40,000 lives touched"
        tagline="Committed to Skill India mission"
        info="Explore our industry-leading services in employability training, manpower solutions, and apprenticeship programs. Partner with us to build a skilled workforce and enhance productivity."
      />
      <CarouselSection />
      <ContactCTASection 
        main="In Today's world of contracts, CAIT builds partnerships"
        tagline=""
        info="Essentially in every partnership lies our effort to comprehend your business and build meaningful solutions that add value and drive agility into your business. Our team comprises some of the brightest sparks from the industry with each individual committed to your success."
      />
      <Footer />
    </>
  );
};

export default Home;