import React from 'react';
import "./CTASection.css"

const CTASection = ({ main, tagline, info }) => {
  return (
    <>
    <div id="cta" className="section p-5">
      <div className="background-overlay"></div>
      <div className="container">
        <div className="row mt-2">
          <div className="col-md-12 text-white text-center">
            <h1 className="white-text">{main}</h1>
            <p className="lead white-text">{tagline}</p>
            <p className="lead white-text">{info}</p>
          </div>
        </div>
      </div>
    </div>
    </>
  );
};

export default CTASection;
