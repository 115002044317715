import React from "react";
import './ServiceComponent.css';

const ServiceComponent = ({ imageSrc, heading, info, linkUrl }) => {
  return (
    <div className="container my-4">
      <div className="row align-items-center">
        <div className="col-md-4">
          <img src={imageSrc} alt="Service Image" className="img-fluid service-img" />
        </div>
        <div className="col-md-8">
          <h3>{heading}</h3>
          <p>{info}</p>
          <a href={linkUrl} className="main-button icon-button">Learn More</a>
        </div>
      </div>
    </div>
  );
};

export default ServiceComponent;
