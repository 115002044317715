import React from 'react';
import LogoSection from '../../components/LogoSection/LogoSection';
import Header from '../../components/Header/Header';
import ContactCTASection from '../../components/ContactCTA/ContactCTASection Component';
import Footer from '../../components/Footer/Footer';
import AccordionComponent from '../../components/AccordionComponent/AccordionComponent';

const ManpowerServices = () => {
  const accordionItemsA = [
    {
      id: 1,
      title: 'Contract Staffing',
      content: `We offer you turn-key, end-to-end contract manpower solutions. Our professional HR consultants with rich and varied experience help in providing right fit candidates from Sourcing till Separation. Our dedicated team of supervisors and in-house HR coordinators interact with the deployed workforce to ensure their basic needs are met and fit in the role, whereas, the client management team ensures that everything from performance review to candidate counselling is managed. We maintain 100% compliance, both in terms of statutory and social, with all our projects engagement.`,

    },

    {
      id: 2,
      title: 'Blue Collar Worker Placement',
      content: `As one of India’s foremost Third Party Manpower service providers, we operate with a vibrant network of sectoral skill councils, skilling missions, rural job fairs, livelihood missions, gram panchayats, sourcing partners, etc. From such talent pool, we mobilize the brightest sparks to add to your workforce… either directly as your employees or to your third-party manpower service providers. Our placement coordinators understand the prerequisites and job description- based on this we source candidates from various channels. Our transparency, collaborative attitude and deep access to sourcing network sets us apart from rest of the ilk.`,
    },
    {
      id: 3,
      title: 'Bulk Hiring',
      content: `In specific industries related to infrastructure, logistics, and industrial development, there is often the requirement for workforce in significant volumes. At such times our clients rely upon us to deliver their mandates- especially for blue collar requirements. During the bulk hiring process, we also extend our service in collecting the required credentials as per employer requirement. Our core strength lies in hiring the rural youths who are ready to migrate as per job location.`,
    },
    
  ];
  return (
    <>
      <Header />
      <LogoSection
        main="Manpower Services"
        secondary="Helping you stay asset light and focused
                  Contract Staffing, Blue Collar Placement, Bulk Hiring"
        backgroundImage="/images/home-background.jpeg" // Path relative to public
      />
       <AccordionComponent
        accordionItems={accordionItemsA}
        title="Manpower Services"
        info="Are you currently recruiting, looking for advice on HR compliance or considering contract staffing? In each of these areas, you may count on our vast experience to deliver your goals. Every year, we place 4000+ candidates across various job roles and sectors. This is just the beginning- because companies all across are exploring options to keep the asset light, reduce overhead costs, and stay focused on their core business operations and growth."
       />
      <ContactCTASection
        main="In Today's world of contracts, CAIT builds partnerships"
        tagline=""
        info="Essentially in every partnership lies our effort to comprehend your business and build meaningful solutions that add value and drive agility into your business. Our team comprises some of the brightest sparks from the industry with each individual committed to your success."
      />
      <Footer />
    </>
  );
};

export default ManpowerServices;
