import React from 'react';
import './ContactForm.css'; // Link your CSS file for custom styles

const ContactCard = () => {
  return (
    <div className="container mt-3 mb-3">
      <div className="card">
        <iframe
          className="card-img-top"
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2038.8869354068509!2d15.21545545625287!3d59.26803797168206!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x465c14e4a4c8e193%3A0xf18609c2d9ee261f!2sLa%20Santa%20Maria%20Tattoos%20%26%20Art!5e0!3m2!1ssv!2sse!4v1568297791174!5m2!1ssv!2sse"
          frameBorder="0"
          style={{ border: 0 }}
          allowFullScreen=""
          aria-hidden="false"
          tabIndex="0"
        ></iframe>
        <div className="card-body">
          <div className="row">
            <div className="col-md-6">
              <h5 className="card-title">Contact Us</h5>
              <p className="card-text">
                Address: 123 Main St, City, State, ZIP Code
                <br />
                Phone: (123) 456-7890
                <br />
                Email: info@example.com
              </p>
            </div>
            <div className="col-md-6">
              <h5 className="card-title">Opening Hours</h5>
              <p className="card-text">
                Monday - Friday: 9:00 AM - 5:00 PM
                <br />
                Saturday: 10:00 AM - 3:00 PM
                <br />
                Sunday: Closed
              </p>
    

            </div>

          </div>
          
        </div>
      </div> 
    </div> 
  );
};

export default ContactCard;
