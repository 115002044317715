import React from 'react';
import "./FounderInfo.css"

const FounderInfo = () => {
  return (
    <div className="container mb-5 mt-5">
      <div className="row align-items-center">
        <div className="col-md-4 text-center">
          <img
            src="/images/Mahesh-Hadpe.jpeg"
            alt="Founder Image"
            className="img-fluid"
          />
        </div>
        <div className="col-md-8">
          <h2>Mr. Mahesh Hadpe</h2>
          <h5>Founder Chairman, CAIT</h5>
          <p>It all began with one man’s goal towards his nation’s progress. CAIT’s founder Mr. Mahesh Hadpe rose from humble beginnings and defied circumstances to complete his Industrial Training and went on to rank India’s No. 1 at the National Skills Competition in 1997. He later completed his Bachelor’s in Engineering from Mumbai’s reputed KJ Sommaya college. A personal testimony to the transformational power of skills education, Mahesh Hadpe has dedicated his efforts and energies towards this mission- shaping CAIT into an integrated service provider for some of the most reputed brands in India.
          </p>
        </div>
      </div>
    </div>
  );
}

export default FounderInfo;
